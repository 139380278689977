































import { Component, Mixins } from 'vue-property-decorator'
import AuthenticationService from '@/general/services/AuthenticationService.ts'
import BlackFullLogo from '@/assets/images/black-full-logo.png'
import Logo from '@/assets/images/logo.png'

import RouterViewWithError from '@/general/components/layouts/RouterViewWithError.vue'
import AboutService from "@/modules/about/AboutService";
import {GetAbout_GetAbout} from "@/generated/GetAbout";
import Popup from "@/general/components/common/Popup.vue";
import InfoCard from "@/general/components/common/InfoCard.vue";
import AboutPopup from "@/general/components/common/AboutPopup.vue";

@Component({
  components: {
    AboutPopup,
    InfoCard,
    Popup,
    RouterViewWithError
  }
})
export default class Unauthorized extends Mixins(AuthenticationService, AboutService) {
  blackFullLogo = BlackFullLogo
  logo = '/assets/img/logo.png'

  commit: string = ''

  about: GetAbout_GetAbout[] = []
  showAbout: boolean = false

  created() {
    this.commit = 'Build: ' + process.env.VUE_APP_COMMIT!!
    this.checkLogin(true)
  }

  imgError() {
    console.log('Using fallback image')
    this.logo = Logo
  }
}
